import React from "react";
import "./Hero.css";
import Header from "../Head/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from 'number-counter';
export default function Hero() {
  const transition = { type: "spring", duration: 3 };
  return (
    <div className="hero" id="hero">
      <div className="blur blur-hero"></div>
      <div className="left">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: "165px" }}
            whileInView={{ left: "8px" }}
            transition={transition}
          ></motion.div>
          <span>The Best Gym in the Town</span>
        </div>
        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape</span>
            <span> Your</span>
          </div>
          <span>Ideal Body</span>
        </div>
        <div>
          <span className="neche">
            in here we will help you to shape and build your ideal body and live
            up your life to fullest
          </span>
        </div>
        {/* figures */}
        <div className="figure">
          <div>
            <span><NumberCounter end={40} start={10} delay = '2' preFix='+'/></span>
            <span>expert coachs</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={780} delay = '2' preFix='+'/></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={56} start={32} delay = '2' preFix='+'/></span>
            <span>fitness programs</span>
          </div>
        </div>
        {/* hero button */}
        <div className="hero-button">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right">
        <button className="btn">Join Now</button>
        <motion.div
        transition={transition}
        initial = {{right:'-1rem'}}
        whileInView={{right: '4rem'}}
         className="heart-rate">
          <img src={heart} alt="" />
          <span>Heart Rate</span>
          <span>93 bpm</span>
        </motion.div>

        {/* hero images */}
        <img src={hero_image} alt="" className="hero-image" />
        <motion.img transition={transition} initial={{right:'11rem'}} whileInView={{right:'20rem'}} src={hero_image_back} alt="" className="hero-image-back" />

        {/* calories */}
        <motion.div
        transition={transition}
        initial={{right:'37rem'}}
        whileInView={{right:'28rem'}}
         className="calories">
          <img src={calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>310 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

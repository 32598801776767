import React from 'react'
import "./Header.css"
import logo from "../../assets/logo.png"
import { Link } from 'react-scroll'
function Header() {
  return (
    <div className="header">
      <img className='logo' src={logo} alt=""/>

      <ul className='header-menu'>
        <li><Link to='hero' span={true} smooth={true}>Home</Link></li>
        <li><Link to='Programs' span={true} smooth={true}>Programs</Link></li>
        <li><Link to='reasons' span={true} smooth={true}>Why us</Link></li>
        <li><Link to='plan' span={true} smooth={true}>Plans</Link></li>
        <li><Link to='test' span={true} smooth={true}>Testimonials</Link></li>
      </ul>
   </div>
  )
}

export default Header
